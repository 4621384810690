import { create } from "zustand";
import { ONBOARDINGSTAGE, defaultShareHolder } from "../../data/onboardingData";
import { persist, createJSONStorage } from "zustand/middleware";
import { isValidEmail } from "../../utilities/general";

const initialState = {
  step: ONBOARDINGSTAGE.BUSINESS_DETAILS,
  email: "",
  phoneNumber: "",
  businessName: "",
  businessId: "",
  locations: [],
  states: [],
  cities: [],
  lgas: [],
  lcdas: [],
  countries: [],
  shareholders: [defaultShareHolder],
  shareholdersErrors: [null],
  merchantDetails: {},
  userNINDetails: {},
  UserBVNDetails: {},
  merchantFullName: "",
  referralCode: "",
  bankAccountNumber: null
};

const useOnboardingStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      resetOnboardingState: () => {
        set((state) => {
          return {
            ...state,
            ...initialState
          };
        });
      },
      setLocations: (location) => {
        set((state) => {
          return {
            ...state,
            locations: location
          };
        });
      },
      setStates: (location) => {
        set((state) => {
          return {
            ...state,
            states: location
          };
        });
      },
      setCountries: (location) => {
        set((state) => {
          return {
            ...state,
            countries: location
          };
        });
      },
      setCity: (location) => {
        set((state) => {
          return {
            ...state,
            cities: location
          };
        });
      },
      setLgas: (location) => {
        set((state) => {
          return {
            ...state,
            lgas: location
          };
        });
      },
      setLcdas: (location) => {
        set((state) => {
          return {
            ...state,
            lcdas: location
          };
        });
      },
      setEmail: (email) => {
        set((state) => {
          return {
            ...state,
            email: email
          };
        });
      },
      setPhoneNumber: (phoneNumber) => {
        set((state) => {
          return {
            ...state,
            phoneNumber
          };
        });
      },
      setBusinessName: (businessName) => {
        set((state) => {
          return {
            ...state,
            businessName: businessName
          };
        });
      },
      setBusinessId: (businessId) => {
        set((state) => {
          return {
            ...state,
            businessId: businessId
          };
        });
      },
      setStep: (step) => {
        set((state) => {
          return {
            ...state,
            step: step
          };
        });
      },
      resetShareholders: () => {
        set((state) => {
          return {
            ...state,
            shareholders: [defaultShareHolder]
          };
        });
      },
      addShareholder: () => {
        set((state) => {
          const newShareholders = [...state.shareholders, defaultShareHolder];
          return {
            ...state,
            shareholders: newShareholders,
            shareholdersErrors: state.shareholdersErrors
          };
        });
      },
      deleteShareholder: (sIndex) => {
        set((state) => {
          const newShareholders = state.shareholders.filter(
            (item, index) => index !== sIndex
          );
          return {
            ...state,
            shareholders: newShareholders,
            shareholdersErrors: state.shareholdersErrors
          };
        });
      },
      updateShareholder: (shareHolderIndex, updatedData) => {
        set((state) => {
          const newShareholders = state.shareholders.map((shareholder, index) =>
            index === shareHolderIndex
              ? { ...shareholder, ...updatedData }
              : shareholder
          );
          return {
            ...state,
            shareholders: newShareholders,
            shareholdersErrors: state.shareholdersErrors
          };
        });
      },
      validateShareholders: () => {
        const state = get();
        const errors = state.shareholders.map((shareholder) => {
          const errorObject = {};
          if (!shareholder.sharesowned) {
            errorObject.sharesowned = "Shares information is required";
          } else if (parseInt(shareholder.sharesowned) < 5) {
            errorObject.sharesowned = "Shares must be greater than 5%";
          }
          if (!shareholder.shareholderbvn) {
            errorObject.shareholderbvn = "BVN is required";
          } else if (shareholder.shareholderbvn.length !== 11) {
            errorObject.shareholderbvn = "BVN must be 11 digits";
          } else if (!shareholder.isBvnValidated) {
            errorObject.shareholderbvn =
              "Invalid BVN. Please validate the BVN.";
          }

          if (!shareholder.idType) {
            errorObject.idType = "ID type is required";
          }
          return Object.keys(errorObject).length > 0 ? errorObject : null;
        });
        set({ shareholdersErrors: errors });
        return errors;
      },

      setUserBVNDetails: (payload) => {
        set((state) => ({
          ...state,
          UserBVNDetails: payload
        }));
      },
      setBusinessVerificationStatus: (status) => {
        set((state) => ({
          ...state,
          businessVerificationStatus: status
        }));
      },

      validateShareholders: () => {
        const state = get();
        const errors = state.shareholders.map((shareholder) => {
          const errorObject = {};
          if (!shareholder.sharesowned) {
            errorObject.sharesowned = "Shares information is required";
          } else if (parseInt(shareholder.sharesowned) < 5) {
            errorObject.sharesowned = "Shares must be greater than 5%";
          }
          if (!shareholder.shareholderbvn) {
            errorObject.shareholderbvn = "BVN is required";
          } else if (shareholder.shareholderbvn.length !== 11) {
            errorObject.shareholderbvn = "BVN must be 11 digits";
          } else if (!shareholder.isBvnValidated) {
            errorObject.shareholderbvn =
              "Invalid BVN. Please validate the BVN.";
          }

          if (!shareholder.idType) {
            errorObject.idType = "ID type is required";
          }
          return Object.keys(errorObject).length > 0 ? errorObject : null;
        });
        set({ shareholdersErrors: errors });
        return errors;
      },
      setMerchantDetails: (payload) => {
        set((state) => ({
          ...state,
          merchantDetails: payload
        }));
      },
      setUserNINDetails: (payload) => {
        set((state) => ({
          ...state,
          userNINDetails: payload
        }));
      },
      setUserBVNDetails: (payload) => {
        set((state) => ({
          ...state,
          UserBVNDetails: payload
        }));
      },
      setMerchantFullName: (payload) => {
        set((state) => ({
          ...state,
          merchantFullName: payload
        }));
      },
      setReferralCode: (code) => {
        // Action to set referralCode
        set((state) => ({
          ...state,
          referralCode: code
        }));
      },
      setBankAccountNumber: (bankAccountNumber) => set({ bankAccountNumber }),
      setBankName: (bankName) => set({ bankName })
    }),

    {
      name: "onboarding-storage",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

export default useOnboardingStore;
