import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import SessionHOC from "../components/hocs/SessionHOC";
import PageNotFound from "../components/pages/404";
import ErrorBoundary from "../components/hocs/ErrorBoundary";
const ReferralSignup = lazy(() =>
  import("../components/pages/onboarding/ReferalCode/ReferralSignup")
);
const ReferralPortal = lazy(() =>
  import("../components/pages/onboarding/ReferalCode/portal/ReferralPortal")
);
const ReferralForgotPassword = lazy(() =>
  import(
    "../components/pages/onboarding/ReferalCode/portal/ReferralForgotPassword"
  )
);
const ReferralLogin = lazy(() =>
  import("../components/pages/onboarding/ReferalCode/ReferalsLogin")
);
const ReferralCodeGeneration = lazy(() =>
  import("../components/pages/onboarding/ReferalCode/ReferralCodeGenerated")
);

const NonMerchantReferralsPortal = () => {
  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={<ReferralLogin />} />
        <Route path="/referralCode" element={<ReferralSignup />} />
        <Route
          path="/referral-forgot-password"
          element={<ReferralForgotPassword />}
        />
        <Route element={<SessionHOC loginURL="/referrals" />}>
          <Route path="/portalContent" element={<ReferralPortal />} />
          <Route path="/generatedCode" element={<ReferralCodeGeneration />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default NonMerchantReferralsPortal;
