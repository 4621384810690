import React from 'react';
import Modal from 'react-responsive-modal';

import Success from '../../assets/svg/green-bg-tick.svg';
import Button from '../inputs/Button';

const SuccessMessage = ({
  title = 'Title', message = 'Message', btnAction = () => {}, btnLabel = 'Okay'
}) => (
  <Modal classNames={{ modal: 'rounded-md p-10 max-w-md' }} open center showCloseIcon={false} onClose={() => {}}>
    <center className="">
      <div className="mb-8">
        <img className="w-10 h-10" src={Success} alt="Success" />
      </div>
      <h3 className="font-bold text-xl mb-5">{title}</h3>
      <p className="mb-10">{message}</p>

      <Button
        className="w-full"
        label={btnLabel}
        onClick={btnAction}
      />
    </center>
  </Modal>
);

export default SuccessMessage;
