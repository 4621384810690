import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../inputs/Button';
import { ReactComponent as Bulb } from '../../assets/svg/Bulb.svg';
import { ReactComponent as Plug } from '../../assets/svg/Plug.svg';
import { ReactComponent as Ellipse } from '../../assets/svg/Ellipse.svg';

function PageNotFound() {
  return (
    <div className="relative overflow-hidden">
      <Plug className="hidden lg:block absolute left-0 top-0" />
      <Ellipse className="hidden lg:block absolute lg:-left-32 xl:left-0 lg:-bottom-40 xl:-bottom-60" />
      <Bulb className="hidden lg:block absolute lg:-right-24 xl:right-0 lg:-bottom-20 xl:-bottom-24" />
      <center className="h-screen flex flex-col items-center text-center justify-center">
        <h4 className="text-chineseBlack text-center text-4xl">
          Oops! The page cannot be found
        </h4>
        <h3 className="text-chinese-black text-center text-200 font-bold">
          404
        </h3>
        <Link to="/">
          <Button label="Back to homepage" className="w-215 h-55" />
        </Link>
      </center>
    </div>
  );
}

export default PageNotFound;
