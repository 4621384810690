import React, { useEffect, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import Modal from "react-responsive-modal";
import Button from "../inputs/Button";
import { logout } from "../../utilities/auth";
import StillThereSvg from "../../assets/svg/still-there.svg";

const InactivityModal = ({
  modalText = "Are you still there?",
  inactivityModal,
  setInactivityModal = (e) => {},
  handleAction = (e) => {},
  timeLeft = 60,
  logoutPath = "/login" 
}) => {
  const [remainingTime, setRemainingTime] = useState(timeLeft);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Modal
      classNames={{
        modal: "rounded-sm"
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      open={inactivityModal}
      onClose={() => setInactivityModal(false)}
      center
      showCloseIcon={false}
    >
      <div className="w-full h-[300px] flex flex-col items-center py-5 px-5">
        <div className="py-2">
          <img
            src={StillThereSvg}
            alt="Still there Logo"
            className="w-32 h-32"
          />
        </div>
        <div className="flex flex-col items-center gap-2 py-2 pb-4">
          <p className="font-semibold text-lg">Are you there?</p>
          <p>
            You'd be logged out due to inactivity in{" "}
            <span className="font-semibold">{remainingTime}s</span>
          </p>
        </div>

        <div className="w-full flex justify-between">
          <Button
            label="Logout"
            color="light"
            onClick={() => logout(logoutPath)} 
            className="w-full mt-auto"
          />
          <Button
            label="Cancel"
            onClick={handleAction}
            className="w-full mt-auto"
          />
        </div>
      </div>
    </Modal>
  );
};

export default InactivityModal;
