import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import qs from "query-string";
import * as Yup from "yup";
import { useFormik } from "formik";

import { AiTwotoneLock } from "react-icons/ai";
import Logo from "../../../../assets/img/logo.png";
import TextInput from "../../../inputs/TextInput";
import { encodeToken, logout} from "../../../../utilities/auth";
import notification from "../../../../utilities/notification";
import { get, patch, post } from "../../../../api-services/fetch";
import Loading from "../../../common/Loading";
import Button from "../../../inputs/Button";
import SuccessMessage from "../../../layouts/SuccessMessage";
import paths from "../../../../api-services/endpoints";

const ChangeReferralPassword = () => {
  const navigate = useNavigate();
  const query = qs.parse(window?.location?.search);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "ALATpay - Change Password";
  }, []);

  const checkLinkValidity = useCallback(
    async (token) => {
      const res = await post({
        endpoint: paths.confirmRefResetLink,
        body: token,
        auth: false,
        useDefaultSubKey: true
      });

      if (res?.status < 200 || res?.status > 300) {
        notification({
          message:
            res?.data?.message ||
            "Invalid link, Kindly try resetting your password again"
        });
        return navigate("/referrals");
      }
    },
    [query?.t]
  );

  useEffect(() => {
    if (!query?.t) {
      return navigate("/referrals");
    }
    checkLinkValidity(query?.t);
  }, []);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter a new password")
      .min(8, "Password must contain 8 or more characters")
      .matches(/[a-z]/, "Password must include a lowercase letter")
      .matches(/[A-Z]/, "Password must include an Uppercase letter")
      .matches(/\d/, "Password must include a digit")
      .matches(
        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
        "Password must include a special character"
      ),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password")
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      password2: ""
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const res = await patch({
        endpoint: "resetReferralPassword",
        body: { newPassword: values.password, validationToken: query?.t },
        auth: false,
        useDefaultSubKey: true
      });
      setIsLoading(false);

      if (res?.status >= 200 && res?.status < 300) {
        encodeToken("da", res?.data?.data);
        encodeToken("t", res?.data?.data?.token);
        setSuccess(true);
      } else {
        notification({
          title: "Change Password Error",
          message:
            res?.data?.message ||
            "An error occurred while trying to reset your password. Please try again.",
          type: "danger"
        });
      }
    }
  });

  return (
    <>
      {isLoading && <Loading />}

      {success && (
        <SuccessMessage
          title="Reset Successful"
          message="You have successfully reset your password"
          btnAction={() => logout("/referrals")}
          btnLabel="Proceed to login"
        />
      )}

      <section className="relative overflow-visible">
        <div className="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap items-center justify-between w-full mdx2:w-auto">
            <Link to="/">
              <span className="sr-only">ALATpay</span>
              <img className="h-8 w-auto sm:h-10 mb-3" src={Logo} alt="Logo" />
            </Link>
            <div className="mb-3">
              Don’t have an account?&nbsp;
              <Link to="/referrals" className="text-alat-dark font-bold">
                Get Started
              </Link>
            </div>
          </div>
        </div>

        <div className="max-w-5xl mx-auto py-20 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto w-full max-w-md">
            <div className="flex items-center mb-2">
              <span className="w-10 h-10 rounded-full bg-alat-dark mr-4 flex items-center justify-center">
                <AiTwotoneLock className="text-white w-7 h-auto" />
              </span>
              <h3 className="font-bold text-2xl">Reset Password</h3>
            </div>
            <p>Provide your new password</p>

            <form
              onSubmit={formik.handleSubmit}
              className="w-full p-5 bg-gray-100 rounded-lg mt-10"
            >
              <TextInput
                className="mb-5 w-full"
                label="New Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                placeholder="Enter password"
                error={formik.touched.password && formik.errors.password}
                hasError={formik.touched.password && formik.errors.password}
              />
              <TextInput
                className="mb-5 w-full"
                label="Confirm New Password"
                name="password2"
                value={formik.values.password2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
                placeholder="Enter password"
                error={formik.touched.password2 && formik.errors.password2}
                hasError={formik.touched.password2 && formik.errors.password2}
              />

              <Button className="w-full" type="submit" label="Reset Password" />
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangeReferralPassword;
