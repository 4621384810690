const prefix = "/api/v1";
const prefix2 = "/api";
const referrals = `${process.env.REACT_APP_REFERRAL_PREFIX}`;
const onboardingPre = "merchant-onboarding";
// const transactionPre = "transaction";
const transactionPre = `${process.env.REACT_APP_TRANSACTION_PREFIX}`;
const settlementPre = "payment-settlement";
const walletPre = "alatpay-wallet";
// const verification = "merchant-onboarding";
const storefrontPre = "alatpaystorefront";
const unregistered = "unregistered-business";
const paymentCard = `${process.env.REACT_APP_PAYMENTCARD_PREFIX}`;



const paths = {
  register: `${onboardingPre}${prefix}/merchants`,
  login: `${onboardingPre}${prefix}/auth/login`,
  logout: `${onboardingPre}${prefix}/auth/logout`,
  refreshToken: `${onboardingPre}${prefix}/auth/refresh-token`,
  newToken: `${onboardingPre}${prefix}/auth/token/renew`,
  updatePassword: `${onboardingPre}${prefix}/auth/password/update`,
  forgetPassword: `${onboardingPre}${prefix}/auth/password/forgot`,
  resetPassword: `${onboardingPre}${prefix}/auth/password/reset`,
  subscriptions: `${onboardingPre}${prefix}/auth/apim/subscriptions`,
  confirmResetLink: `${onboardingPre}${prefix}/auth/password/isvalid-password-reset-token`,
  confirmReferralResetLink: `${onboardingPre}${prefix}/auth/password/isvalid-password-reset-token`,

  getBanks: `${onboardingPre}${prefix}/banks`,
  verifyAccountNo: `${onboardingPre}${prefix}/banks/accountName`,
  verifyEmailRequest: `${onboardingPre}${prefix}/merchants/email/verification-request`,
  verifyPhoneRequest: (phoneNumber) =>
    `${onboardingPre}${prefix}/unregistered-business/resendOtp/${phoneNumber}`,
  confirmEmailToken: `${onboardingPre}${prefix}/merchants/email/verification-confirmation`,

  merchant: `${onboardingPre}${prefix}/merchants`,
  updateExistingBusinessKyc: (businessId) =>
    `${onboardingPre}${prefix}/merchants/businesses/${businessId}/update-existing-business-kyc`,
  resubmission: `${onboardingPre}${prefix}/businessVerification/BusinessInfoReSubmission`,
  getLocation: `${onboardingPre}${prefix}/merchants/get-countries-info`,
  merchantData: (merchantId) =>
    `${onboardingPre}${prefix}/merchants/${merchantId}`,
  merchantWithId: (merchantId) =>
    `${onboardingPre}${prefix}/merchants/${merchantId}/businesses`,
  merchantBusiness: (businessId) =>
    `${onboardingPre}${prefix}/merchants/businesses/${businessId}`,
  pauseBusiness: (businessId) =>
    `${onboardingPre}${prefix}/merchants/businesses/${businessId}/update-status`,
  merchantPhoto: (merchantId) =>
    `${onboardingPre}${prefix}/merchants/upload-profile-photo/${merchantId}`,
  transactions: `${transactionPre}${prefix}/transactions`,

  settlements: `${settlementPre}${prefix}/settlements`,

  talkToSales: `${settlementPre}${prefix}/sales/create-message`,

  chart: `${settlementPre}${prefix}/analytics`,

  getTransactionSummary: `${transactionPre}${prefix}/transactions/get-transaction-summary`,

  getAllCustomers: `${transactionPre}${prefix}/transactions/get-all-customers`,

  getSettlementSummary: `${settlementPre}${prefix}/settlements/get-all-merchant-settlement-summary-details`,
  downloadSettlementRecords: `${settlementPre}${prefix}/settlements/download-settlement-report-for-Business`,
  downloadStaticWallets: `${settlementPre}${prefix}/static-account/StaticWalletReport`,

  createPaymentLink: `${onboardingPre}${prefix}/paymentlink`,
  getPaymentLinkDetails: `${onboardingPre}${prefix}/paymentlink`,
  updatePaymentLink: `${onboardingPre}${prefix}/paymentlink`,
  getPaymentLinkByReferenceId: `${onboardingPre}${prefix}/paymentlink/getall-paylinks-by-reference`,
  getAllBusinessPaymentLinks: `${onboardingPre}${prefix}/paymentlink/getall-paylinks-by-businessid`,
  getAllBusinessPaymentLinksCount: `${onboardingPre}${prefix}/paymentlink/get-counts`,
  getBusinessPricingHistory: `${onboardingPre}${prefix}/pricing/get-all-merchant-pricing-audit-trail`,
  getGeneralPricing: `${onboardingPre}${prefix}/pricing/get-general-pricing`,
  getBusinessPricing: (businessId) =>
    `${onboardingPre}${prefix}/pricing/get-business-pricing/${businessId}`,

  getAllWalletsByBusinessId: (businessId) =>
    `${walletPre}${prefix}/wallet/business/${businessId}`,
  getWalletById: (walletId) =>
    `${walletPre}${prefix}/wallet/business/${walletId}`,
  getWalletTransactions: `${walletPre}${prefix}/wallet/transaction-histories`,
  initiateWalletDebit: (walletId) =>
    `${walletPre}${prefix}/wallet/initiate-wallet-debit/${walletId}`,
  processWalletDebit: (walletId) =>
    `${walletPre}${prefix}/wallet/process-wallet-debit/${walletId}`,

  //   getBvnInformation: (bvn) =>
  //     `${onboardingPre}${prefix}/businessVerification/BvnValidation?bvn=${bvn}`,
  getBvnInformationonboarding: (bvn, businessId, flag) =>
    `${onboardingPre}${prefix}/businessVerification/BvnValidation?businessId=${businessId}&bvn=${bvn}`,
  getchargeback: (businessid) =>
    `${settlementPre}${prefix}/charge-back/get-by-business/${businessid}`,
  putchargeback: (id, businessid) =>
    `${settlementPre}${prefix}/charge-back/${id}/process-accept-reject-chargeback/${businessid}`,
  downloadChargebackReport: (businessid) =>
    `${settlementPre}${prefix}/charge-back/download-spreadsheet-chargeback-report/${businessid}`,
  validateBVNforUnregisterbusiness: `${onboardingPre}${prefix}/${unregistered}/validate-bvn-phoneNumber`,
  // Unregistered Business
  validateBVNforUnregisterbusiness: `${onboardingPre}${prefix}/${unregistered}/validate-bvn-phoneNumber`,
  validateNINPhoneNumber: `${onboardingPre}${prefix}/${unregistered}/validate-nin-phoneNumber`,
  validateBVNforUnregisterbusiness: `${onboardingPre}${prefix}/${unregistered}/validate-bvn-phoneNumber`,
  validateUnregisteredBusinessOtp: (merchantId, otp) =>
    `${onboardingPre}${prefix}/${unregistered}/${merchantId}/validate-otp/${otp}`,

  validateUnregisteredBusinessBVNOtp: (merchantId, bvnvalidationID, otp) =>
    `${onboardingPre}${prefix}/${unregistered}/validate-bvn-otp?MerchantId=${merchantId}&BvnValidationId=${bvnvalidationID}&otp=${otp}`,

  processUnregisteredBusiness: (merchantId, businessId) =>
    `${onboardingPre}${prefix}/${unregistered}/${merchantId}/process-business/${businessId}`,

  // Tokenization
  getPaymentPlanSubscriptions: `${paymentCard}${prefix}/paymentCard/mc/get-payment-plan-subscriptions`,
  getPaymentPlanSubscription: `${paymentCard}${prefix}/paymentCard/mc/get-payment-plan-subscription`,
  searchPaymentPlanSubscriptions: `${paymentCard}${prefix}/paymentCard/mc/searchPaymentPlanSubscriptions`,
  createPaymentPlan: `${paymentCard}${prefix}/paymentCard/mc/create-payment-plan`,
  getPaymentPlan: `${paymentCard}${prefix}/paymentCard/mc/get-payment-plans`,
  getPaymentPlanById: `${paymentCard}${prefix}/paymentCard/mc/get-payment-plan-by-Id`,
  completeTransaction: `${paymentCard}${prefix}/paymentCard/mc/complete-transaction`,

  // Storefront
  createStorefront: `${storefrontPre}${prefix2}/StoreFront/CreateStoreFront`,
  checkStorefrontSlug: (slug) =>
    `${storefrontPre}${prefix2}/StoreFront/IsStoreSlugUnique?storeSlug=${slug}`,
  getStoreByBusinessId: `${storefrontPre}${prefix2}/StoreFront/GetAllStoreFronts`,
  createProductCategory: `${storefrontPre}${prefix2}/ProductCategory/CreateProductCategory`,
  editProductCategory: `${storefrontPre}${prefix2}/ProductCategory/EditProductCategory`,
  deleteProductCategory: `${storefrontPre}${prefix2}/ProductCategory/DeleteProductCategory`,
  getProductCategories: `${storefrontPre}${prefix2}/ProductCategory/GetProductCategories`,
  createProduct: `${storefrontPre}${prefix2}/Product/AddProduct`,
  editProduct: `${storefrontPre}${prefix2}/Product/EditProduct`,
  deleteProduct: `${storefrontPre}${prefix2}/Product/DeleteProduct`,
  getFaqs: `${storefrontPre}${prefix2}/storefront/GetFaqs`,
  getAllOrders: `${storefrontPre}${prefix2}/Order/GetAllOrders`,
  blacklistCustomer: `${storefrontPre}${prefix2}/Order/BlackListCustomer`,
  downloadCustomersReport: `${storefrontPre}${prefix2}/Order/DownloadCustomerReport`,
  getAllProducts: `${storefrontPre}${prefix2}/Product/GetAllProducts`,
  getProductById: `${storefrontPre}${prefix2}/Product/GetProductById`,
  getInvoiceById: `${storefrontPre}${prefix2}/Invoice/GetInvoiceById`,
  getAllInvoices: `${storefrontPre}${prefix2}/Invoice/GetAllInvoices`,
  generateInvoice: `${storefrontPre}${prefix2}/Invoice/GenerateInvoice`,
  deleteInvoice: `${storefrontPre}${prefix2}/Invoice/DeleteInvoice`,
  editInvoice: `${storefrontPre}${prefix2}/Invoice/editInvoice`,
  markInvoiceAsPaid: `${storefrontPre}${prefix2}/Invoice/markInvoiceAsPaid`,
  getAllStoreCustomers: `${storefrontPre}${prefix2}/Order/GetAllCustomers`,
  getStoreCustomerById: `${storefrontPre}${prefix2}/Order/GetCustomerById`,
  getStoreOrderById: `${storefrontPre}${prefix2}/Order/GetOrderById`,
  updateOrderStatus: `${storefrontPre}${prefix2}/Order/UpdateOrderStatus`,
  getStoreReviews: `${storefrontPre}${prefix2}/StoreFront/GetReviewsAndRatings`,
  getStoreRatingAverage: `${storefrontPre}${prefix2}/StoreFront/GetAverageRating`,
  addFaq: `${storefrontPre}${prefix2}/StoreFront/AddFrequentlyAskedQuestions`,
  deleteFaq: `${storefrontPre}${prefix2}/StoreFront/DeleteFAQ`,
  activateShippingOption: () =>
    `${storefrontPre}${prefix2}/StoreFront/ActivateShippingOption`,
  getShippingData: `${storefrontPre}${prefix2}/StoreFront/GetSpecificShippingPreferenceDetails`,
  addDeliveryFeeAndLocation: `${storefrontPre}${prefix2}/StoreFront/AddDeliveryFeeAndLocation`,
  editDeliveryFeeAndLocation: `${storefrontPre}${prefix2}/StoreFront/EditDeliveryInformation`,
  deleteFeeAndLocation: `${storefrontPre}${prefix2}/StoreFront/DeleteDeliveryInfo`,
  addPickUpLocation: `${storefrontPre}${prefix2}/StoreFront/AddPickUpLocation`,
  editPickupLocation: `${storefrontPre}${prefix2}/StoreFront/EditPickupInformation`,
  deletePickUpLocation: `${storefrontPre}${prefix2}/StoreFront/DeletePickupInfo`,
  editFaq: `${storefrontPre}${prefix2}/StoreFront/EditFaq`,
  updateShippingOption: `${storefrontPre}${prefix2}/Storefront/UpdateShippingOption`,
  updateStoreSettings: `${storefrontPre}${prefix2}/StoreFront/UpdateStoreSettings`,
  setStoreGoLive: (storeId) =>
    `${storefrontPre}${prefix2}/StoreFront/Go-live/${storeId}`,

  // Static Wallet
  staticWallet: `${walletPre}${prefix}/staticaccount`,
  staticWalletCollectionHistory: `${walletPre}${prefix}/staticaccount/collectionhistory`,
  staticWalletById: `${walletPre}${prefix}/staticaccount/staticAccountId`,

  //Referral
  verifyReferralEmailRequest: `${referrals}${prefix2}/auth/email/verification-request`,
  confirmReferralEmailToken: `${referrals}${prefix2}/auth/email/verification-confirmation`,
  referral: `${referrals}${prefix2}/auth/create-referrer`,

  accountveification: `${referrals}${prefix2}/referral/banks/accountName`,
  nonmerchantLogin: `${referrals}${prefix2}/auth/login`,
  referralforgetPassword: `${referrals}${prefix2}/auth/password/forgot`,
  getAllearningssummary: (referralCode) =>
    `${referrals}${prefix2}/referral/get-earnings-summary?ReferralCode=${referralCode}`,
  getAlTransantionsSummary: (referralCode) =>
    `${referrals}${prefix2}/referral/get-all-referrals-earnings?ReferralCode=${referralCode}`,
  getAllEairngTransantionsSummary: () =>
    `${referrals}${prefix2}/referral/get-all-referrals-earnings`,
  getAllEairngTransantionsMerchant: (referralCode) =>
    `${referrals}${prefix2}/referral/get-all-referrals-earnings?ReferralCode=${referralCode}`,
  getAllReferralEarningDetails: (
    referralCode,
    merchantId,
    PageNumber,
    LimitSize
  ) =>
    `${referrals}${prefix2}/referral/get-referral-earnings-details?PageNumber=${PageNumber}&Limit=${LimitSize}&MerchantId=${merchantId}&ReferralCode=${referralCode}`,
  resetReferralPassword: `${referrals}${prefix2}/auth/password/reset`,
  confirmRefResetLink: `${referrals}${prefix2}/auth/password/isvalid-password-reset-token`
};
export default paths;
