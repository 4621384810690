import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';

import { AiTwotoneLock } from 'react-icons/ai';
import notification from '../../../../utilities/notification';
import { post } from '../../../../api-services/fetch';
import { Log } from '../../../../utilities/general';
import Loading from '../../../common/Loading';
import Logo from '../../../../assets/img/logo.png';
import TextInput from '../../../inputs/TextInput';
import Button from '../../../inputs/Button';

const ForgotRefPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'ALATpay - Forgot Password';
  }, []);

  const handleReset = async () => {
    if (!email || !/^([a-zA-Z0-9_\-&.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,10})$/.test(email?.trim())) {
      notification({
        title: 'Error',
        message: 'Please enter a valid email address',
        type: 'danger'
      });
      return;
    }
    setIsLoading(true);
    const res = await post({
      endpoint: "referralforgetPassword",
      body: { email },
      auth: false,
      useDefaultSubKey: true
    });
    setIsLoading(false);
    Log(res);

    if (res?.status >= 200 && res?.status < 300) {
      setSuccess(true);
    } else {
      notification({
        title: 'Forgot Password Error',
        message: res?.data?.message || 'An error occurred while trying to reset your password. Please try again.',
        type: 'danger'
      });
    }
  };

  const handleKeyPress = (e) => {
    if (!isLoading && e.which === 13) {
      handleReset();
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <section className="relative overflow-visible">
        <div className="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap items-center justify-between w-full mdx2:w-auto">
            <Link to="/">
              <span className="sr-only">ALATpay</span>
              <img
                className="h-8 w-auto sm:h-10 mb-3"
                src={Logo}
                alt="Logo"
              />
            </Link>
            <div className="mb-3">
              Already have an account?&nbsp;
              <Link to="/login" className="text-alat-dark font-bold">Sign In</Link>
            </div>
          </div>
        </div>

        <div className="max-w-5xl mx-auto py-20 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto w-full max-w-md">
            <div className="flex items-center mb-2">
              <span className="w-10 h-10 rounded-full bg-alat-dark mr-4 flex items-center justify-center">
                <AiTwotoneLock className="text-white w-7 h-auto" />
              </span>
              <h3 className="font-bold text-2xl">Forgot Password</h3>
            </div>
            <p>Provide your registered email address</p>

            <div className="w-full p-5 bg-gray-100 rounded-lg mt-10">
              <TextInput
                className="mb-5 w-full"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={handleKeyPress}
                type="email"
                placeholder="Enter email address"
              />
            </div>

            <div className="mt-5">
              <Button className="w-full" onClick={handleReset} label="Reset Password" />
            </div>
          </div>
        </div>
      </section>

      <Modal classNames={{ modal: 'rounded-md p-10' }} open={success} center showCloseIcon={false} onClose={() => {}}>
        <center className="">
          <div className="mb-8">
            <span className="w-10 h-10 rounded-full bg-alat-dark flex items-center justify-center">
              <AiTwotoneLock className="text-white w-7 h-auto" />
            </span>
          </div>
          <h3 className="font-bold text-xl mb-8">Password Reset</h3>
          <p className="mb-4">A password reset link has been sent to your registered email.</p>

          <p className="mb-6">Proceed to your email to continue</p>

          <Button className="w-full" label="Okay" onClick={() => setSuccess(false)} />
        </center>
      </Modal>
    </>
  );
};

export default ForgotRefPassword;
