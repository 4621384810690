/* eslint-disable max-len */
import React, { lazy, Suspense } from 'react';
import {
  Route, BrowserRouter as Router, Routes, Navigate
} from 'react-router-dom';

import { ReactNotifications } from 'react-notifications-component';
import 'react-responsive-modal/styles.css';
import NonMerchantReferralsPortal from "./routes/NonMerchantReferalPortal";
import Loading from './components/common/Loading';
import CombineContextProvider from './context';
// eslint-disable-next-line import/no-named-as-default
import ErrorBoundary from './components/hocs/ErrorBoundary';
import PrivacyPolicy from './components/pages/onboarding/PrivacyPolicy';
import ForgotRefPassword from './components/pages/onboarding/ReferalCode/ForgotRefPassword';
import ChangeReferralPassword from './components/pages/onboarding/ReferalCode/ChangeReferralPassword';
import VerifyBvnNumber from './components/pages/onboarding/unregisteredBusiness/VerifyBVNNumber';

const Index = lazy(() => import('./components/pages/Index'));
const Login = lazy(() => import('./components/pages/onboarding/Login'));
const IndexProfileContinue = lazy(() => import('./components/pages/onboarding/IndexProfileContinue'));
const MerchantProfile = lazy(() => import('./components/pages/onboarding/MerchantProfile'));
const MerchantRegistration = lazy(() => import('./components/pages/onboarding/MerchantRegistration'));

const MerchantSignup = lazy(() => import('./components/pages/onboarding/MerchantSignup'));
const BusinessDetails = lazy(() => import('./components/pages/onboarding/BusinessDetails'));
const UserPortal = lazy(() => import('./components/portals/UserPortal'));
const ForgotPassword = lazy(() => import('./components/pages/onboarding/ForgotPassword'));
const ChangePassword = lazy(() => import('./components/pages/onboarding/ChangePassword'));
const TalkToSales = lazy(() => import('./components/pages/onboarding/TalkToSales'));
const TermsAndConditions = lazy(() => import('./components/pages/onboarding/TermsAndConditions'));
const Pricing = lazy(() => import('./components/pages/onboarding/Pricing'));
const PageNotFound = lazy(() => import('./components/pages/404'));
const BusinessCategory = lazy(() => import('./components/pages/onboarding/unregisteredBusiness/BusinessCategory'));
const ProfileVerification = lazy(() => import('./components/pages/onboarding/unregisteredBusiness/ProfileVerification'));
const NINVerification = lazy(() => import('./components/pages/onboarding/unregisteredBusiness/NINVerification'));
const VerifyPhoneNumber = lazy(() => import('./components/pages/onboarding/unregisteredBusiness/VerifyPhoneNumber'));
const MerchantRegistrationForUnregisteredBusiness = lazy(() => import('./components/pages/onboarding/unregisteredBusiness/MerchantRegistration'));
const BVNVerification = lazy(() =>
  import("./components/pages/onboarding/unregisteredBusiness/BVNVerification")
);

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary>
        <Router>
          <CombineContextProvider>
            <ReactNotifications />
            <Routes>
              <Route path="/index.html" element={<Navigate to="/" />} />
              <Route path="/" element={<Index />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ChangePassword />} />
              <Route
                path="/index-profile-cont"
                element={<IndexProfileContinue />}
              />
              <Route path="/verify-bvn" element={<VerifyBvnNumber />} />
              <Route path="/merchant-profile" element={<MerchantProfile />} />
              <Route
                path="/merchant-registration"
                element={<MerchantRegistration />}
              />
              <Route path="/merchant-signup" element={<MerchantSignup />} />
              <Route path="/business-details" element={<BusinessDetails />} />
              <Route path="/business-category" element={<BusinessCategory />} />
              <Route
                path="/profile-verification"
                element={<ProfileVerification />}
              />
              <Route path="/nin-verification" element={<NINVerification />} />
              <Route
                path="/referrals/*"
                element={<NonMerchantReferralsPortal />}
              />
              <Route
                path="/forgot-ref-password"
                element={<ForgotRefPassword />}
              />
              <Route
                path="/reset-referral-password"
                element={<ChangeReferralPassword />}
              />
              <Route
                path="/referrals/*"
                element={<NonMerchantReferralsPortal />}
              />
              <Route path="/bvn-verification" element={<BVNVerification />} />
              <Route
                path="/merchant-registration-unregisteredBusiness"
                element={<MerchantRegistrationForUnregisteredBusiness />}
              />
              <Route
                path="/verify-phone-number"
                element={<VerifyPhoneNumber />}
              />
              <Route path="/talk-to-sales" element={<TalkToSales />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              <Route path="/portal/*" element={<UserPortal />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </CombineContextProvider>
        </Router>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
