import { useEffect, useState } from "react";
import { decodeToken, logout, refreshToken } from "../../utilities/auth";
import InactivityModal from "../common/InactivityModal";
import { useIdleTimer } from "react-idle-timer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const SessionHOC = ({ loginURL = "/login", referralURL = "/referrals" }) => {
  const [showSessionModal, setShowSessionModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const onPrompt = () => {
    if (decodeToken("da")) {
      setShowSessionModal(true);
    } else {
      setShowSessionModal(false);
    }
  };

  const onAction = () => {
    if (decodeToken("da")) {
      if (Math.ceil(getRemainingTime() / 1000) <= 295 && !showSessionModal) {
        // handleRefresh();
      }
    }
  };

  const onIdle = () => {
    if (decodeToken("da")) {
      setShowSessionModal(false);
      if (location.pathname.startsWith("/portal")) {
        navigate(loginURL);
      } else if (location.pathname.startsWith("/referrals")) {
        navigate(referralURL);
      } else {
        navigate(loginURL);
      }
    } else {
      setShowSessionModal(false);
    }
  };
  const { isIdle, reset, getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle,
    promptBeforeIdle: 1000 * 60 * 1,
    onPrompt,
    onAction
  });

  const handleReset = () => {
    reset();
    setShowSessionModal(false);
  };

  useEffect(() => {
    const inter = setInterval(() => {
      const expiryTime = decodeToken("timer");
      const currentTime = Date.now();

      if((parseInt(expiryTime) - currentTime) < 1 * 60 * 1000) { // Less than 1 minute to expiry
        refreshToken();
      }
    }, 1 * 60 * 1000); // Check every 2 mintes
  
    return () => {
      clearInterval(inter);
    };
  }, []);
  

  return (
    <>
      {showSessionModal && (
        <InactivityModal
          inactivityModal={showSessionModal}
          handleAction={handleReset}
          setInactivityModal={setShowSessionModal}
          timeLeft={Math.ceil(getRemainingTime() / 1000)}
          logoutPath={
            location.pathname.startsWith("/referrals") ? referralURL : loginURL
          } 
        />
      )}
      <Outlet />
    </>
  );
};

export default SessionHOC;
