import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { RiAccountCircleFill } from "react-icons/ri";

import { post } from "../../../../api-services/fetch";
import notification from "../../../../utilities/notification";
import Loading from "../../../common/Loading";

import TextInput from "../../../inputs/TextInput";
import { toggleScroll } from "../../../../utilities/general";
import useOnboardingStore from "../../../../context/onboarding/onboardingStore";
import { Form, Formik } from "formik";
import { otpValidationSchema } from "../validations/OnboardingValidation";
import Logo from "../../../../assets/img/logo.png";
import paths from "../../../../api-services/endpoints";
import { decodeToken } from "../../../../utilities/auth";
import OtpRateLimiter from "../../../common/OtpRateLimiter";

const VerifyBvnNumber = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    setStep,
    step,
    email,
    UserBVNDetails,
    merchantDetails,
    setMerchantDetails
  } = useOnboardingStore();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = location?.state?.isAuthenticated;
  const bvnvalidationID = UserBVNDetails?.id
 

  useEffect(() => {
    toggleScroll();

    if (!UserBVNDetails?.id) navigate("/profile-verification");
    if (!isAuthenticated) {
      navigate("/merchant-signup", { state: { step: 1, email } });
    }

    return () =>
      setTimeout(() => {
        toggleScroll();
      }, 0);
  }, []);

  const verifyOtp = async (otp) => {
    const merchantId =
      merchantDetails &&
      (merchantDetails?.id ||
        merchantDetails?.merchantDto?.id ||
        decodeToken("da")?.id);

    if (!otp || otp?.length < 6) {
      notification({
        title: "No OTP",
        message:
          "Please, enter the OTP that has been sent to your phone number.",
        type: "danger"
      });
      return;
    }

    if (!merchantId || merchantDetails?.registrationStage < 7) {
      notification({
        title: "No OTP",
        message: "Invalid profile",
        type: "danger"
      });
      navigate("/merchant-signup", { state: { step: 1, email } });
    }

    setIsLoading(true);
    const res = await post({
      endpoint: paths?.validateUnregisteredBusinessBVNOtp(
        merchantId,
        bvnvalidationID,
        otp
      ),
      body: { merchantId, bvnvalidationID, otp },
      auth: false,
      useDefaultSubKey: true
    });
    setIsLoading(false);
    

    if (res?.status >= 200 && res?.status < 300) {
      const data = res?.data?.data;
      setMerchantDetails(data);
      notification({
        title: "Successful Verification",
        message: `Your phone number has been verified successfully.`,
        type: "success"
      });

      setStep(8);

      const ninNameLength = (data?.businessNinFullName?.length || 0) + 2;
      navigate("/merchant-registration-unregisteredBusiness", {
        state: {
          isAuthenticated: true,
          maxBusinessNameLength: Math.max(0, 50 - ninNameLength)
        }
      });
    } else {
      notification({
        title: "Error",
        message:
          res?.data?.message ||
          `An error occurred while trying to verify your phone number. Please try again.`,
        type: "danger"
      });
    }
  };

  const resendOtp = async () => {
    setIsLoading(true);
    const res = await post({
      endpoint: paths.verifyPhoneRequest(UserBVNDetails?.id),
      auth: false,
      useDefaultSubKey: true
    });
    setIsLoading(false);

    if (res?.status >= 200 && res?.status < 300) {
      notification({
        title: "OTP Resent",
        message: "Verification OTP has been resent to your phone number.",
        type: "success"
      });
    } else {
      notification({
        title: "Error",
        message:
          res?.data?.message ||
          "An error occurred while trying to verify your phone number. Please try again.",
        type: "danger"
      });
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      <Formik
        initialValues={{
          otp: ""
        }}
        onSubmit={async (values, { setSubmitting }) => {
          verifyOtp(values.otp);
        }}
        validateOnBlur
        validationSchema={otpValidationSchema}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          values
        }) => (
          <Form>
            <section className="relative overflow-visible">
              <div className="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
                <div className="flex flex-wrap items-center justify-between w-full mdx2:w-auto">
                  <Link to="/">
                    <span className="sr-only">ALATpay</span>
                    <img
                      className="h-8 w-auto sm:h-10 mb-3"
                      src={Logo}
                      alt="Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="max-w-5xl mx-auto pt-10 pb-20 px-4 sm:px-6 lg:px-8">
                <div className="mx-auto w-full max-w-md">
                  <div className="flex items-center mb-2">
                    <RiAccountCircleFill className="text-alat-light mr-4 w-10 h-auto" />
                    <h3 className="font-bold text-2xl">
                      Verify your phone number
                    </h3>
                  </div>
                  <p className="text-[#1F1F1F]">
                    An OTP has been sent to{" "}
                    <span className="font-bold">
                      {UserBVNDetails?.phoneNumber || "your phone number.."}
                    </span>{" "}
                    for verification. Kindly enter the OTP in the text box
                    below.
                  </p>

                  <div className="w-full p-5 bg-gray-100 rounded-lg mt-10">
                    <TextInput
                      labelClassName="font-bold"
                      className="w-full"
                      pattern="[0-9]*"
                      label="OTP"
                      name="otp"
                      type="password"
                      value={values?.otp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={6}
                      placeholder="Enter verification code"
                    />
                    {touched.otp && errors.otp && (
                      <span className="text-red-500">{errors.otp}</span>
                    )}
                  </div>

                  <div className="mt-5">
                    <button
                      className="w-full bg-alat-light hover:bg-alat-light text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                    <div className="w-full flex justify-around">
                      <span className="mx-auto mt-2">
                        Didn't receive an otp?{" "}
                        <OtpRateLimiter
                          initialCount={60 * 5}
                          resendOtp={resendOtp}
                          text={<span>Resend otp</span>}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default VerifyBvnNumber;
