import moment from "moment-timezone";
export const toggleScroll2 = (enable = true) => {
  const body = document.body;
  const scrollY = enable ? "" : `-${window.scrollY}px`;

  body.style.position = enable ? "" : "fixed";
  body.style.top = scrollY;
};
export const toggleScroll = () => {
  const overlays = document.querySelectorAll(".overlay");

  if (overlays.length > 0) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }
};

export const convertImgToBase64 = (inputFile) => {
  if (inputFile === undefined) return "";
  const file = new FileReader();

  return new Promise((resolve, reject) => {
    file.onerror = () => {
      file.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    file.onload = () => {
      resolve(file.result);
    };
    file.readAsDataURL(inputFile);
  });
};

export const formatStatus = (status) => {
  switch (status?.toLowerCase()) {
  case "pending":
    return "Pending";
  case "open":
    return "Pending";
    // case 'completed': return 'Completed';
  case "completed":
    return "Success";
  case "failed":
    return "Failed";

  case "pending72hours":
    return "Pending";
  case "approved":
    return "Approved";
  case "rejected":
    return "Rejected";
  case "paused":
    return "Disabled";

  default:
    return status;
  }
};

export const formatTransferStatus = (status) => {
  switch (status) {
  case 0:
    return "Initiated";
  case 1:
    return "Failed";
  case 2:
    return "Successful";
  case 3:
    return "Pending";
  default:
    return status;
  }
};

export const formatPaymentMethod = (methodId) => {
  switch (methodId?.toString()) {
  case "*":
    return "All";
  case "1":
    return "Card Payment";
  case "2":
    return "Bank Transfer";
  case "3":
    return "Bank Account";
  case "4":
    return "NQR";
  case "5":
    return "Pay with Phone Number";

  default:
    return methodId;
  }
};

export const formatFileUrl = (path) => {
  if (path) return `${process.env.REACT_APP_API_BASE_URL}/${path}`;
  return null;
};

export const getCurrency = (currency) => {
  switch (currency) {
  case "ngn":
    return "₦";
  case "NGN":
    return "₦";
  case 2:
    return "₦";
  case 1:
    return "$";
  case "USD":
    return "$";
  case "usd":
    return "$";
  case "GBP":
    return "£";
  case "gbp":
    return "£";
  default:
    return "₦";
  }
};

export const formatCurrency = (value, currency) => {
  if (value && !currency) {
    let val = value;
    val = val ? parseFloat(val).toFixed(2) : 0.0;
    return val === 0 ? "₦0.00" : `₦${Number(val).toLocaleString("en-US")}`;
  }
  if(value && currency){
    let val = value;
    val = val ? parseFloat(val).toFixed(2) : 0.0;
    return val === 0 ? `${currency}0.00` : `${currency}${Number(val).toLocaleString("en-US")}`;
  }
  return `${currency || "₦"}0.00`;
};

export const concealEmail = (email = "") =>
  email.replace(/(?!^).(?=[^@]+@)/g, "*");
export const concealValue = (value = "") =>
  value ? value.replace(/\w/g, "*") : "";

export const Log = (...logs) =>
  process.env.NODE_ENV === "development"
    ? console.log(...logs, `(Log time - ${moment().format("LLL")})`)
    : undefined;

export const format2Digits = (num) => {
  if (typeof num !== "number" || isNaN(num)) return 0;

  return (
    num.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false
    }) || 0
  );
};

export const hasError = (array) => {
  for (const obj of array) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (key === "email" || key === "phoneNumber") {
          if (value === null || value === undefined) {
            return true;
          }
        } else {
          if (value === null || value === undefined || value === "") {
            return true;
          }
        }
      }
    }
  }

  return false;
};

export const isValidEmail = (value) => {
  if (value === null || value === undefined || value === "") {
    return true;
  }

  return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);
};

export const currencyFormatter = ({ amount, currency }) => {
  if (!amount) return `${currency || "NGN"} 0.00`;
  const currencyCheck =
    currency === "NGN" || currency === "USD" || currency === "GBP"
      ? currency
      : "NGN";
  const NGNformattedAmount = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: currencyCheck // NGN or USD
  }).format(amount);
  const USDformattedAmount = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: currencyCheck // NGN or USD
  }).format(amount);
  const GBPformattedAmount = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: currencyCheck // NGN or USD
  }).format(amount);

  let formattedAmount = amount;
  switch (currency) {
  case "NGN":
    // console.log(formattedAmount);
    formattedAmount = NGNformattedAmount.replace("₦", "NGN").replace(/([A-Za-z]+)([0-9.]+)/, '$1 $2');
    break;
  case "USD":
    formattedAmount = USDformattedAmount.replace("US$", "USD").replace(/([A-Za-z]+)([0-9.]+)/, '$1 $2');
    break;
  case "GBP":
    formattedAmount = GBPformattedAmount;
    break;
  default:
    formattedAmount = `NGN ${amount}`;
    break;
  }

  return formattedAmount;
};

export const currencyEnum = {
  NGN: 1,
  USD: 2,
  GBP: 3
};

export const moneyInputFormat = (value) => {
  //string | number

  let amount = value.toString();
  if (amount === "" || amount === undefined || amount === "0.")
    return {
      withMoneyFormat: "0",
      withNumberFormat: 0
    };

  // remove the , from the amount before formating
  const removeComma = amount.split(",").join("");
  // Haddle a regex test
  const reg = /^\d+\.?\d{0,4}?$/g;

  if (reg.test(removeComma)) {
    let formatedData = Number(removeComma)
      .toPrecision()
      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$&,");
    const checkIfDecimal = removeComma.split(".");
    if (checkIfDecimal.length > 1 && checkIfDecimal[1] === "")
      formatedData = `${formatedData}.`;

    if (formatedData.split("")[0] === "0")
      return {
        withMoneyFormat: formatedData.slice(1, formatedData.length),
        withNumberFormat: Number(formatedData.slice(1, formatedData.length))
      };

    return {
      withMoneyFormat: formatedData,
      withNumberFormat: Number(removeComma)
    };
  }
};

export const paymentPlanFrequency = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3
};

export const paymentPlanType = {
  INSTALLMENT: 1,
  RECURRING: 2,
  WALLETFUNDING: 3
};

export const installmentStatus = {
  PENDING: 0,
  ACTIVE: 1,
  COMPLETED: 2,
  OVERDUE: 3
};

export const formatInstallmentStatus = (status) => {
  switch (status) {
  case 0:
    return "Pending";
  case 1:
    return "Active";
  case 2:
    return "Completed";
  case 3:
    return "Overdue";
  default:
    return status;
  }
};

export const formatInstallmentStatusColor = (status) => {
  switch (status) {
  case 0:
    return "#FEA800";
  case 1:
    return "#FEA800";
  case 2:
    return "#129007";
  case 3:
    return "#CD0505";
  default:
    return "#FEA800";
  }
};

export const formatPaymentPlanType = (status) => {
  const stat = Number(status);

  if (stat === isNaN(stat) || stat === undefined) return status;
  switch (stat) {
  case 1:
    return "Installment";
  case 2:
    return "Recurring";
  case 3:
    return "Wallet Funding";
  default:
    return status;
  }
};

export const formatPaymentPlanFrequency = (status) => {
  const stat = Number(status);

  if (stat === isNaN(stat) || stat === undefined) return status;
  switch (stat) {
  case 1:
    return "Daily";
  case 2:
    return "Weekly";
  case 3:
    return "Monthly";
  default:
    return status;
  }
};

export const formatPaymentPlanFrequencies = (status, duration) => {
  const stat = Number(status);
  const singular = (!Number(duration) || Number(duration) === 1) ? "" : "s";

  if (stat === isNaN(stat) || stat === undefined) return status;
  switch (stat) {
  case 1:
    return `Day${singular}`;
  case 2:
    return `Week${singular}`;
  case 3:
    return `Month${singular}`;
  default:
    return status;
  }
};

export const inputMode = (inputType) => {
  switch (inputType) {
  case "number":
    return "numeric";
  case "decimal":
    return "decimal";
  case "tel":
    return "tel";
  case "url":
    return "url";
  case "email":
    return "email";
  case "search":
    return "search";
  case "checkbox":
    return "none";
  case "button":
    return "none";
  case "file":
    return "none"
  case "hidden":
    return "none";
  case "image":
    return "none";
  case "radio":
    return "none";
  case "reset":
    return "none";
  case "submit":
    return "none";
  default:
    return "text";
  }
};


const appendLeadZero = (val) => (Number(val) > 9 ? val : `0${val}`);
export const formatSimpleDate = (date) => {
  const d = new Date(date);
  if (d.toString() === "Invalid Date") return null;
  return `${d.getFullYear()}-${appendLeadZero(
    d.getMonth() + 1,
  )}-${appendLeadZero(d.getDate())}`;
};